<!--
 * @Author: gjm
 * @Date: 2021-04-15 13:18:02
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-09-02 17:30:58
 * @Description: 在线咨询-创建问题
-->
<style lang="less" scoped>
/deep/ .ant-form-item {
  margin-bottom: 12px !important;
}
.source-target {
  text-align: center;
  font-size: 20px;
}
</style>

<template>
  <a-modal
    :visible="questionContinue"
    class="create-question"
    :width="576"
    title="创建问题"
    @cancel="handleCancel"
    okText="提交"
    @ok="handleOk"
  >
    <div class="question-box">
      <a-form-model ref="question" :model="form" :rules="rules" :label-col="{ span: 4 }" :wrapper-col="{ span: 19 }">
        <a-form-model-item label="问题">
          <div class="text-wrap pt-1" style="line-height: 23px">
            {{ latestDetail ? latestDetail.questionContext : question.questionContext }}
          </div>
        </a-form-model-item>
        <a-form-model-item label="解决方法">
          <div class="text-wrap pt-1" style="line-height: 23px">
            {{
              latestDetail
                ? latestDetail.orderQuestionReplyDTOList
                  ? latestDetail.orderQuestionReplyDTOList[0].replyContext
                  : null
                : question.orderQuestionReplyDTOList[0].replyContext
            }}
          </div>
        </a-form-model-item>
        <a-form-model-item label="追问" required prop="questionContext">
          <a-textarea
            v-model="form.questionContext"
            placeholder="请输入您的问题......"
            :auto-size="{ minRows: 3, maxRows: 5 }"
            :maxlength="500"
          />
        </a-form-model-item>

        <a-form-model-item label="语言对">
          <a-row>
            <a-col :span="11">
              <a-select
                allowClear
                show-search
                :filter-option="langFilterOption"
                v-model="form.sourceCode"
                :placeholder="$t('orderInfo.source')"
                disabled
              >
                <a-select-option v-for="(item, index) in sourceCodeList" :key="index" :value="item.code">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col :span="2">
              <div class="source-target">></div>
            </a-col>
            <a-col :span="11">
              <a-select
                allowClear
                show-search
                :filter-option="langFilterOption"
                v-model="form.targetCodeList"
                :placeholder="$t('orderInfo.target')"
                mode="multiple"
                disabled
              >
                <a-select-option v-for="(item, index) in targetCodeList" :key="index" :value="item.code">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-col>
          </a-row>
        </a-form-model-item>

        <a-form-model-item label="附件">
          <NeoUploadButton
            title="上传附件"
            v-model="form.attachmentFile"
            :showNote="form.attachmentFile.length > 0 ? false : true"
            :showFileList="true"
            :showIcon="true"
            accept=".doc, .docx, .xlsx, .xls, .pdf, .png, .jpg, .zip"
            :maxSize="10"
            :maxLength="1"
            note="支持doc、docx、xlsx、xls、pdf、png、jpg、zip格式，且大小不超过10M"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import { mapState } from 'vuex'
import NeoUploadButton from '@/components/upload/NeoUploadButton'
import debounce from 'lodash/debounce'

const { validate, acceptMap } = window.$g

export default {
  name: 'QuestionContinue',
  components: {
    NeoUploadButton,
  },
  props: {
    questionContinue: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: () => {},
    },
    question: {
      type: Object,
      default: () => {},
    },
    latestDetail: {
      //最新的问题详情
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      acceptMap,
      uploading: false,
      form: {
        questionContext: undefined,
        attachmentFile: [],
        sourceCode: undefined,
        targetCodeList: undefined,
      },
      rules: {
        questionContext: [validate.required],
      },
      sourceCodeList: [],
      targetCodeList: [],
    }
  },
  computed: {
    ...mapState('app', {
      langList: (state) => state.langList,
    }),
  },
  watch: {
    questionContinue(v) {
      if (v) {
        console.log('this.question :>> ', this.question)
        this.form.sourceCode = this.question.sourceCode ? this.question.sourceCode : undefined
        this.form.targetCodeList = this.question.targetCode ? this.question.targetCode?.split(',') : undefined
        this.sourceCodeList = this.langList.filter((_) => _.code == this.question.sourceCode)
        this.targetCodeList = this.langList.filter((_) => this.question.targetCode?.split(',').includes(_.code))
      } else {
        this.form.questionContext = undefined
        this.form.attachmentFile = []
        this.$refs.question.clearValidate()
      }
    },
  },
  methods: {
    langFilterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },

    handleCancel() {
      this.$emit('closeQuestionContinue', false)
    },
    handleOk: debounce(function () {
      try {
        const params = {
          orderId: this.question.orderId,
          pid: this.question.id,
          questionContext: this.form.questionContext,
          sourceCode: this.form.sourceCode,
          targetCode: this.form.targetCodeList?.join(','),
          attachmentFile: this.form.attachmentFile.length > 0 ? this.form.attachmentFile[0].url : null,
          attachmentName: this.form.attachmentFile.length > 0 ? this.form.attachmentFile[0].name : null,
          questionerType: this.$g.isCust ? 1 : 2,
          questionType: 2,
        }
        this.$refs.question.validate(async (valid) => {
          if (valid) {
            await this.$http('createQuestion', params)
              .then(() => {
                this.$emit('confirm')
                this.$emit('closeQuestionContinue', false)
              })
              .catch((err) => {
                this.$httpNotify(err)
              })
          }
        })
      } catch (error) {
        this.$httpNotify(error)
      }
    }, 1000),
  },
}
</script>
